/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";
import khaan from "./images/khaan.png"
import golomt from "./images/golomt.png"
import qpay from "./images/qpay.jpg"
import "./scss/companySelfCreateCompleted.scss"

const SignupCompleted = () => {

    return (
        <div className="w-full px-4 bg-white">

            <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between"}}>
                <div className="text-black mt-2">
                    <div className="text-sm text-center">
                        <strong style={{color: "#002C6C"}}>“GS1 Монгол”-ын гишүүнээр амжилттай
                            бүртгэгдлээ.</strong>
                        <br/><br/>


<div className="text-center" style={{backgroundColor:"#F26334", padding:"15px"  }}>
                        <strong style={{fontSize:"18px", color: "#FFFFFF"}}>Доорх дансаар төлбөрөө төлж  info@gs1mn.org хаягруу Улсын бүртгэлийн гэрчилгээний ар, урд хэсгийн зураг /хувь хүн бол иргэний үнэмлэхний зураг/, албан тоот-ын хамт явуулсны дараа  НЭВТРЭХ НЭР, НУУЦ ҮГ мэйл хаягаар очно.</strong>
</div>
                    </div>

                    <div style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        alignItems: "flex-start",
                        justifyContent: "center"
                    }}>
                        <div style={{margin: "30px"}}>
                            <div style={{marginTop: "20px", marginBottom: "20px"}}>
                                <img src={khaan} style={{height: "50px"}}/>
                            </div>

                            <table className="account">
                                <tbody>
                                <tr>
                                    <th>Регистрийн дугаар:</th>
                                    <td>8218528</td>
                                </tr>
                                <tr>
                                    <th>Дансны дугаар:</th>
                                    <td>ХААН БАНК 562 2272 133 /MNT/</td>
                                </tr>
                                <tr>
                                    <th>Хүлээн авагчийн нэр:</th>
                                    <td>ДЭЛХИЙН СТАНДАРТ НЭГ МОНГОЛ НИЙГЭМЛЭГ</td>
                                </tr>
                                </tbody>
                            </table>

                            <div style={{marginTop: "20px", marginBottom: "20px"}}>
                                <img src={golomt} style={{height: "50px"}}/>
                            </div>

                            <table className="account">
                                <tbody>
                                <tr>
                                    <th>Регистрийн дугаар:</th>
                                    <td>8218528</td>
                                </tr>
                                <tr>
                                    <th>Дансны дугаар:</th>
                                    <td>ГОЛОМТ БАНК 3635128206 /MNT/</td>
                                </tr>
                                <tr>
                                    <th>Хүлээн авагчийн нэр:</th>
                                    <td>ДЭЛХИЙН СТАНДАРТ НЭГ МОНГОЛ НИЙГЭМЛЭГ</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-8 mb-3">
                            <img src={qpay} style={{marginTop: "20px", width: "250px"}}/>
                        </div>

                    </div>
                    <div className="text-center text-sm mb-3 px-2">
                    <strong style={{color: "#002C6C" }}>Төлбөрийн утган
                        дээрээ байгууллагын нэр, регистр, утасны дугаараа бичнэ үү.<br/>
                        Танд амжилт хүсье!</strong>
                    </div>



                    <div><a target="_blank" href={"http://gs1mn.org"}>Буцах</a></div>
                </div>
            </div>

            <div style={{paddingBottom: "20px", paddingTop: "20px"}}>
                <hr/>
                <br/>
                <p><small>
                    GS1 Монгол Нийгэмлэг. <strong>Манай хаяг:&nbsp;</strong>
                    &nbsp;Монгол Улс, Улаанбаатар хот, 17032, Хан-Уул дүүрэг, 3-р
                    хороо, 19-р хороолол, "Морьтон" цогцолбор, 9 давхарт 902 тоот.{" "}
                    <strong>Утас</strong> +976-77 000 865. <strong>Email</strong>{" "}
                    <a href="mailto: info@gs1mn.org"> info@gs1mn.org</a>&nbsp;© 2019
                    GS1 Монгол Нийгэмлэг. Зохиогчийн эрх хуулиар хамгаалагдсан.
                </small>
                </p>
            </div>
        </div>
    )

}

export default SignupCompleted